.product-cart-container,
.product-wishlist-container{
    padding: 20px 0;
    min-height: 750px;
}
.section-head{
    text-align: center;
    color: #444;
    font-size: 28px !important;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 30px;
}

.section-head:after{
    content: '';
    display: block;
    clear: both;
    width: 40%;
    height: 2px;
    border-top: 2px dashed #e7e7e7;
    margin: 10px auto 0;
}