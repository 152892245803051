 .u-section-1 {
  min-height: 926px;
}

.u-section-1 .u-group-1 {
  min-height: 135px;
  height: auto;
  margin-top: 12px;
  margin-bottom: 0;
}

.u-section-1 .u-container-layout-1 {
  padding-left: 30px;
  padding-right: 30px;
}

.u-section-1 .u-image-1 {
  width: 257px;
  margin-top: 0;
  margin-bottom: 0;
  top: 0;
  left: 30px;
}

.u-section-1 .u-image-2 {
  height: 391px;
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: -126px;
  margin-bottom: -8px;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 522px;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px;
}

.u-section-1 .u-image-3 {
  width: 337px;
  height: 328px;
  margin: 12px auto 0 0;
}

.u-section-1 .u-layout-cell-2 {
  min-height: 522px;
}

.u-section-1 .u-container-layout-3 {
  padding: 30px 0;
}

.u-section-1 .u-text-1 {
  margin: 46px 30px 0;
}

.u-section-1 .u-text-2 {
  font-size: 1.5rem;
  margin: 16px -1px 0;
}

.u-section-1 .u-form-1 {
  height: 218px;
  width: 410px;
  margin: 7px auto 0;
}

.u-section-1 .u-input-1 {
  --radius: 12px;
  font-weight: 700;
  background-image: none;
}

.u-section-1 .u-input-2 {
  --radius: 12px;
  font-weight: 700;
  background-image: none;
}

.u-section-1 .u-btn-1 {
  --radius: 19px;
  background-image: linear-gradient(#fc5c00, #fc0000);
  font-weight: 700;
  padding: 10px 52px 10px 51px;
}

.u-section-1 .u-text-3 {
  font-size: 1.125rem;
  margin: 0 30px;
}

.u-section-1 .u-layout-cell-3 {
  min-height: 522px;
}

.u-section-1 .u-container-layout-4 {
  padding: 18px 15px;
}

.u-section-1 .u-image-4 {
  height: 340px;
  width: 339px;
  margin: 24px 0 -12px auto;
}

@media (max-width: 1199px) {
   .u-section-1 {
    min-height: 834px;
  }

  .u-section-1 .u-group-1 {
    height: auto;
  }

  .u-section-1 .u-image-1 {
    width: 287px;
    left: 0;
    right: auto;
    margin-top: initial;
    margin-bottom: initial;
  }

  .u-section-1 .u-image-2 {
    inset: auto;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 430px;
  }

  .u-section-1 .u-image-3 {
    width: 280px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 430px;
  }

  .u-section-1 .u-text-1 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-form-1 {
    width: 313px;
  }

  .u-section-1 .u-text-3 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-layout-cell-3 {
    min-height: 430px;
  }

  .u-section-1 .u-image-4 {
    height: 285px;
    width: 284px;
  }
}

@media (max-width: 991px) {
   .u-section-1 {
    min-height: 733px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 329px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 469px;
  }

  .u-section-1 .u-text-1 {
    width: auto;
    margin-top: 56px;
  }

  .u-section-1 .u-text-2 {
    font-size: 1.25rem;
    width: auto;
    margin-top: 17px;
  }

  .u-section-1 .u-form-1 {
    width: 240px;
    margin-top: 6px;
  }

  .u-section-1 .u-text-3 {
    margin-top: -10px;
  }

  .u-section-1 .u-layout-cell-3 {
    min-height: 329px;
  }

  .u-section-1 .u-image-4 {
    height: 212px;
    width: 211px;
  }
}

@media (max-width: 767px) {
   .u-section-1 {
    min-height: 1266px;
  }

  .u-section-1 .u-group-1 {
    margin-top: 25px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-layout-wrap-1 {
    margin-top: -134px;
    margin-bottom: 0;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 400px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 450px;
  }

  .u-section-1 .u-text-2 {
    margin-top: -86px;
  }

  .u-section-1 .u-form-1 {
    width: 540px;
    margin-top: 31px;
  }

  .u-section-1 .u-text-3 {
    width: auto;
  }

  .u-section-1 .u-layout-cell-3 {
    min-height: 0;
  }

  .u-section-1 .u-container-layout-4 {
    padding: 0 10px;
  }

  .u-section-1 .u-image-4 {
    height: 249px;
    width: 249px;
    margin-top: -787px;
    margin-right: 15px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
   .u-section-1 {
    min-height: 1085px;
  }

  .u-section-1 .u-image-2 {
    height: 193px;
  }

  .u-section-1 .u-layout-wrap-1 {
    margin-top: -53px;
    margin-bottom: 60px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 181px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .u-section-1 .u-image-3 {
    width: 417px;
    height: 165px;
    margin-top: 0;
    margin-left: auto;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 359px;
  }

  .u-section-1 .u-container-layout-3 {
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .u-section-1 .u-text-1 {
    margin-top: 57px;
  }

  .u-section-1 .u-form-1 {
    width: 340px;
  }

  .u-section-1 .u-image-4 {
    height: 189px;
    margin-top: 0;
    margin-bottom: -189px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }
}