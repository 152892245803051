/* .u-section-1{
  background-image:url('/public/images/Exicon_Event-Desk_Background.png');
  background-repeat:no-repeat;
  background-size: cover;
} */
.u-section-1 .u-sheet-1 {
  min-height: 689px;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: 38px;
  margin-bottom: 0;
  background-image: none;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 100px;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px 30px 23px;
}

.u-section-1 .u-text-1 {
  margin: 0;
}

.u-section-1 .u-layout-cell-2 {
  min-height: 100px;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px 30px 23px;
}

.u-section-1 .u-text-2 {
  margin: 0;
}

.u-section-1 .u-line-1 {
  margin-top: 22px;
  margin-bottom: 0;
  transform-origin: left center;
}

.u-section-1 .u-btn-1 {
  --radius: 6px;
  border-style: solid;
  font-weight: 700;
  font-size: 0.9375rem;
  margin: 37px 0 0 auto;
  padding: 15px 53px 16px 51px;
}

.u-section-1 .u-table-1 {
  margin-top: 22px;
  margin-bottom: 38px;
}

.u-block-6453-16 {
  font-size: 1rem;
}

.u-section-1 .u-table-entity-1 {
  min-height: 100px;
}

.u-section-1 .u-table-header-1 {
  background-image: none;
  font-size: 1rem;
  font-weight: 700;
}

.u-section-1 .u-table-cell-6 {
  font-size: 1rem;
}

.u-section-1 .u-table-cell-8 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
  margin-left: 0;
  padding: 10px 29px 10px 28px;
}

.u-section-1 .u-btn-2 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-table-cell-9 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
  margin-left: 0;
  padding: 10px 29px 10px 28px;
}

.u-section-1 .u-btn-3 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-btn-4 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-table-cell-11 {
  font-size: 1rem;
}

.u-section-1 .u-table-cell-13 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
  margin: -275px 335px 60px auto;
}

.u-section-1 .u-btn-5 {
  border-style: none;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-table-cell-14 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
  margin: -275px 335px 60px auto;
}

.u-section-1 .u-btn-6 {
  border-style: none;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-btn-7 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-image: none;
}

.u-section-1 .u-table-footer-1 {
  font-weight: 700;
}

@media (max-width: 1199px) {
  .u-section-1 .u-table-cell-13 {
    margin-right: 135px;
  }

  .u-section-1 .u-table-cell-14 {
    margin-right: 135px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-table-cell-13 {
    margin-right: 0;
  }

  .u-section-1 .u-table-cell-14 {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 789px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}.responsive-sm {
overflow-y:auto;
}

/* General container style */
.responsive-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

/* Social Icons Container */
.social-icons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap; /* Allows wrapping on small screens */
}

.social-url {
  display: inline-block;
}

.social-icon {
  width: 50px;
  height: 50px;
}

.social-icon svg, .social-icon img {
  width: 100%;
  height: 100%;
}

/* Logout Container */
.logout-container {
  margin-top: 10px;
  text-align: center;
}

.logout-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.logout-icon img {
  width: 20px;
  height: 20px;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .social-icon {
      width: 40px;
      height: 40px;
  }

  .logout-icon img {
      width: 15px;
      height: 15px;
  }
}

@media (max-width: 480px) {
  .social-icon {
      width: 30px;
      height: 30px;
  }

  .logout-icon img {
      width: 12px;
      height: 12px;
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Make sure the container takes up full height */
  width: 100%; /* Make sure the container takes up full width */
}
.navbar-toggler {
  border: none; /* Remove border if needed */
  background: none; /* Ensure the background is transparent */
}

.navbar-toggler-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
}

.navbar-toggler-icon svg {
  width: 100%;
  height: 100%;
}
.search-input {
  width: 200px; /* Adjust the width as needed */
  font-size: 14px; /* Adjust the font size if necessary */
  padding: 8px; /* Adjust the padding for a smaller look */
}
.navbar {
  background-color: #38077B; /* Background color */
  height: 82px; /* Set desired height */
  line-height: 82px; /* Center text vertically */
}
/* Adjust spacing for navbar links */
.navbar-nav .nav-link {
  padding-top: 0; /* Remove top padding */
  padding-bottom: 0; /* Remove bottom padding */
}

/* Responsive design adjustments */
@media (max-width: 820px){
  .navbar {
      height: auto; /* Allow height to adjust on small screens */
      line-height: normal; /* Reset line-height */
  }
  .navbar-nav{
    margin-top: -24px !important;
  }

}
.tableAction{
  margin : 30px;
  margin-left: 10px;
  color: #ffffff;
  padding: 10px 29px 10px 28px;
  background-color: #fc0000 !important;
}
.tableAction:hover{
  background-color: #000000 !important;
  color:#ffffff;
  text-decoration:none;
}
.tableAction1 {
  font-size: 13px; /* Adjusted for better readability on smaller screens */
  margin: 5px; /* Reduced margin for better spacing */
  color: #ffffff;
  padding: 8px 12px; /* Adjusted padding for better look */
  background-color: #fc0000 !important;
  text-decoration: none; /* Ensure links are not underlined */
  border-radius: 4px; /* Optional: Add rounded corners for better aesthetics */
  display: inline-block; /* Make links behave like inline elements */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}
.tableAction1Green{
  font-size: 13px; /* Adjusted for better readability on smaller screens */
  margin: 5px; /* Reduced margin for better spacing */
  color: #ffffff;
  padding: 8px 12px; /* Adjusted padding for better look */
  background-color: #228B22 !important;
  text-decoration: none; /* Ensure links are not underlined */
  border-radius: 4px; /* Optional: Add rounded corners for better aesthetics */
  display: inline-block; /* Make links behave like inline elements */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}
.tableAction1Blue{
  font-size: 13px; /* Adjusted for better readability on smaller screens */
  margin: 5px; /* Reduced margin for better spacing */
  color: #ffffff;
  padding: 8px 12px; /* Adjusted padding for better look */
  background-color: #5f4bcf !important;
  text-decoration: none; /* Ensure links are not underlined */
  border-radius: 4px; /* Optional: Add rounded corners for better aesthetics */
  display: inline-block; /* Make links behave like inline elements */
  transition: background-color 0.3s, color 0.3s; 

}

.tableAction1:hover,.tableAction1Blue:hover,.tableAction1Green:hover {
  background-color: #000000 !important;
  color: #ffffff;
  text-decoration:none;
}

/* Media Query for devices with a max-width of 768px (tablets and small screens) */
@media (max-width: 768px) {
  .tableAction1,.tableAction1Blue,.tableAction1Green {
    font-size: 14px; /* Smaller font size for smaller screens */
    padding: 6px 10px; /* Reduced padding for smaller screens */
    margin: 3px; /* Reduced margin for smaller screens */
  }
}
.jSjmgx{
  word-break: normal !important ;
}
/* Media Query for devices with a max-width of 576px (mobile devices) */
@media (max-width: 576px) {
  .tableAction1,.tableAction1Blue,.tableAction1Green {
    font-size: 12px; /* Even smaller font size for mobile screens */
    padding: 4px 8px; /* Further reduced padding for mobile screens */
    margin: 2px; /* Further reduced margin for mobile screens */
  }
}
.tableActionGreen{
  margin : 30px;
  margin-left: 10px;
  color: #ffffff;
  padding: 8px 12px; 
  background-color: #228B22 !important;
}
.tableActionGreen:hover{
  background-color: #000000 !important;
  color:#ffffff;
  text-decoration:none;
}
.tableActionGreen1{
  margin : 30px;
  margin-left: 10px;
  color: #ffffff;
  padding: 10px;
  background-color: #228B22 !important;
  border-radius: 4px; 
}
.tableActionGreen1:hover{
  background-color: #000000 !important;
  color:#ffffff;
  text-decoration:none;
}
.hZUxNm:last-child{
  margin-left:5%;
}
.jgVPyf {
  margin-left: -12px !important;
}
.custom-button {
  background-color: #e30000 !important; /* Bootstrap primary color */
  border-color: #e30000 !important; /* Match border color with background */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.custom-button:hover {
  opacity: 0.7; /* Opacity on hover */
}
.custom-footer{
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Space between items, adjust as needed */
}
.modal-open{
  padding:0 !important;
}
.gMeyEf{
  overflow : hidden !important;
}
@media (max-width:1100px){
  .gMeyEf{
    overflow-x   : scroll !important;
  }
}
@media (max-width: 576px) {
  .nav-tabs .nav-link {
      font-size: 0.875rem; /* Adjust font size for small screens */
  }
}

@media (min-width: 768px) {
  .nav-tabs .nav-link {
      font-size: 1rem; /* Default font size for larger screens */
  }
}

/* Ensure the tab content container adapts to screen size */
.tab-content {
  padding: 1rem; /* Add padding for better spacing */
}
.estimateButtonActive, .estimateButtonInActive {
  cursor: pointer;
  color: #ffffff !important;
  padding: 10px 20px; /* Adjusted padding */
  border-radius: 10px;
  margin: 10px; /* Margin for spacing between buttons */
  text-decoration: none; /* Remove underline from links */
  display: inline-block; /* Ensure buttons align correctly */
  font-size: 16px; /* Font size */
}

.estimateButtonActive {
  background-color: #fc0000 !important;
}

.estimateButtonInActive {
  background-color: #38077B !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .estimateButtonActive, .estimateButtonInActive {
    padding: 8px 15px; /* Reduced padding for smaller screens */
    font-size: 14px; /* Slightly smaller font size */
  }
}

@media (max-width: 576px) {
  .estimateButtonActive, .estimateButtonInActive {
    display: block; /* Stack buttons vertically on extra small screens */
    margin: 10px auto; /* Center buttons with margin auto */
    width: 80%; /* Full width with some margin */
  }
}
/* Image container for fade effects */
.image-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-container.show {
  opacity: 1;
}

.image-container.hide {
  opacity: 0;
}

/* Thumbnail styling */
.image-thumbnail {
  display: inline-block;
  border: 2px solid transparent;
  transition: border-color 0.1s ease;
}

.image-thumbnail:hover {
  border-color: #38077B; /* Highlight border color on hover */
}

/* Quantity controls styling */
.quantity-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.quantity-button {
  background-color: #38077B;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}

.quantity-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.quantity-display {
  font-size: 18px;
  font-weight: bold;
}

.btn-primary {
  background-color: #38077B;
  border-color: #38077B;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #38077B;
  border-color: #38077B;
}
.btn-primary:disabled {
  background-color: #38077B;
  border-color: #38077B;
  color: #999;
  cursor: not-allowed;
  opacity: 0.65;
  transition: none; /* Disable transition effects */
}
.btn-primary-cart {
  background-color: #38077B;
  border-color: #38077B;
  font-size: 16px;
  border-radius: 5px;
  color:white;
}

.btn-primary-cart:hover {
  background-color: #38077B;
  border-color: #38077B;
  color : white;
}
.btn-primary-cart:disabled {
  background-color: #38077B;
  border-color: #38077B;
  color: #999;
  cursor: not-allowed;
  opacity: 0.65;
  transition: none; /* Disable transition effects */
}
.navbarActive{
  background-color:#228B22;
  border-radius:10px;
}
.navbarPadding{
  padding:14px;
}