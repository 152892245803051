/* #section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

#inner {
  display: flex;
  justify-content: center;
}

#RegisterForm {
  width: 300px;
  display: flex;
  flex-direction: column;
} */

#section1{
  /* margin: 50px; */
  display:  flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
#RegisterForm{
  width: 300px;
}
#inner{
  display: flex;
  justify-content: center;
}
